import React, {useContext, useEffect, useState, useMemo, useCallback, useRef, useLayoutEffect} from 'react';
import {renderToString} from 'react-dom/server';
import {observer} from "mobx-react-lite";
import NoodlesRain from '../../components/NoodlesRain/NoodlesRain';
import {Context} from "../../index";
import {
    fetchProducts,
    getDataForBreadcrumbs,
    getCategory,
    getCategories,
    mindboxViewCategory,
    fetchFilterContext,
    countFilterdProducts
} from "../../http/catalogApi";

import {mindboxSetCart} from "../../http/basketApi";

import './catalogstyle.css';
import BreadcrmbsAndFilterLine from "../../components/catalog/BrcrmbAndFilter/BreadcrmbsAndFilterLine";
import {Link, useParams} from "react-router-dom";
import OneProductCart from "./OneProductCart";
import Spiner from "../administrator/spiner/Spiner";
import {login} from "../../http/userAPI";
import {Helmet} from "react-helmet";
import Pagination from "../../components/Paginstion";
import BreadcrumbsCatalog from "../../components/catalog/BrcrmbAndFilter/breadcrumbsCatalog/BreadcrumbsCatalog";
import {pushGoogleAnalyticProductAction} from "../../utils/googleAnalytic";
import {useLocation, useNavigate} from "react-router";
import NotFound from '../content/notFound/NotFound';
import { FilterContext } from "../../services/FilterContext";
import { getParentCategories } from '../../http/catalogApi';

const parrentTitles = {
    sale: 'Sale',
    UNISEX: 'UNISEX',
    velvetin: 'YW X VELVETIN',
    'new-collection': 'Новинки',
    coming_soon: 'Скоро в наличии',
    bestseller: 'Бестселлеры',
}

const Catalog = observer(() => {

    const {settings} = useContext(Context);
    const [filters, setFilters] = useState({colors: [], sizes: [], trends: [], price_current: []})
    const googleAnalyticProducts = {}
    const [notFound, setNotFound] = useState(false);
    let totalProductsCount = 0;
    const shippedToAnalyticProductIds = []
    let analyticProductsList = []
    const [applyFilter, setApplyFilter] = useState(0);
    const [disabledFilterBtn, setDisabledFilterBtn] = useState(false);
    const [appliedFilters, setaAppliedFilters] = useState({colors: [], sizes: [], trends: [], price_current: []});
    const [actualCat, setActualCat] = useState(false);
    const [containerWidth, setContainerWidth] = useState(1812);
    const [catalogFakeCategory, setCatalogFakeCategory] = useState([]);

    const fetchCatalogFakeCategory = () => {
		getParentCategories().then((data) => {
            if(data.success){
                setCatalogFakeCategory(data.data);
            }
		});
	};
	useEffect(() => {
		fetchCatalogFakeCategory();
	}, []);

    const pushGoogleAnalyticViewItemList = async () => {
        if(!analyticProductsList.length)
            return false
        pushGoogleAnalyticProductAction('view_item_list', analyticProductsList)

        analyticProductsList = []
    }

    const productsObserver = new IntersectionObserver((recordsList ) => {
        recordsList.forEach((record) => {
            if(record.isIntersecting) {
                const {id} = record.target.dataset
                if(!id || shippedToAnalyticProductIds.includes(id))
                    return
                analyticProductsList.push(googleAnalyticProducts[id])
                shippedToAnalyticProductIds.push(id)

                if(analyticProductsList.length >= 15 || shippedToAnalyticProductIds.length >= totalProductsCount)
                    pushGoogleAnalyticViewItemList()
            }
        })
    })

    const location = useLocation();
    const [pathName, setPathName] = useState('') ;

    useLayoutEffect(() => {
        if (window.outerWidth > 1920) {
            setContainerWidth(1812);
        } else if (window.outerWidth > 1438) {
            setContainerWidth(1360);
        } else if (window.outerWidth > 1025) {
            setContainerWidth(906);
        } else {
            setContainerWidth(763);
        }
    }, []);

    useEffect(() => {
        if(location) {
            let tmp = location.search
            setPathName(tmp) ;
        }
    }, [location])

    const params = useParams()
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search)
    let page = +queryParams.get('page')
    let sort = queryParams.get('sort')
    if (!sort) {
        sort = 'default'
    }
    if (page === 0) {
        page = 1
    }

    if (location.search) {
        const params = new URLSearchParams(location.search);
        page = params.get("page");
        if (!page || page <= 0) {
            page = 1
        }
    } else if (!page || page <= 0) {
        page = 1
    }
    let pageSuffix = '';
    if (page && pathName && page > 1) pageSuffix = ' - страница №' + page;

    const limit = 32;
    const [count, setCount] = useState(0)
    const [countFiltered, setCountFiltered] = useState(0)

    const [getNewProductsActive, setGetNewProductsActive] = useState(false);

    const [productsArray, setProductsArray] = useState([]);
    const [productsFilterArray, setProductsFilterArray] = useState([]);
    const [pageCatalogError, setPageCatalogError] = useState("");
    const [spiner, setSpiner] = useState(true);
    const [category, setCategory] = useState(false)
    const [thirdCategory, setThirdCategory] = useState(false)
    const [fourthCategory, setFourthCategory] = useState(false)
    const [parrentCategory, setParrentCategory] = useState(null)
    const [deviceUUID, setDeviceUUID] = useState('')
    const [userAgent, setUserAgent] = useState('')
    const [mindboxCat, setMindboxCat] = useState('')
    const [showFilters, setShowFilters] = useState(true)

    useEffect(() => {
        setDeviceUUID(document.cookie.replace(/(?:(?:^|.*;\s*)mindboxDeviceUUID\s*\=\s*([^;]*).*$)|^.*$/, "$1"))
        setUserAgent(window.navigator.userAgent)
    })

    useEffect(() => {
        if (category && Object.keys(category).length > 0 && category != mindboxCat) {
            setMindboxCat(category)
            mindboxViewCategory(deviceUUID, userAgent, category)
        }
    }, [category])

    useEffect(() => {
        if (parrentCategory && Object.keys(parrentCategory).length > 0 && parrentCategory != mindboxCat && !category) {
            setMindboxCat(parrentCategory)
            mindboxViewCategory(deviceUUID, userAgent, parrentCategory)
        }
    }, [parrentCategory])

    const parrent = params.parrentCategory
    const child = params.childCategory
    const third = params.thirdCategory
    const fourth = params.fourthCategory
    const [paramsForBreadcrumbs, setParamsForBreadcrumbs] = useState();

   if(parrent == 'sale' && sort == 'default'){
       sort= 'price_up'
   }


    useEffect(() => {
        setSeoText(false)
        if (
            (parrent == 'sale'
            || parrent == 'bestseller'
            || parrent == 'coming_soon'
            || parrent == 'new-collection')
            && category
        ) {
            setSeoText('')
            return
        }
        if(page !== 1){
            setSeoText('')
        } else if (fourthCategory){
            if (fourthCategory.seo_text) {
                setSeoText(fourthCategory.seo_text);
            }
        } else if(thirdCategory){
            if(thirdCategory.seo_text){
                setSeoText(thirdCategory.seo_text)
            }
        } else if(category){
            if(category.seo_text){
                setSeoText(category.seo_text)
            }
        } else if(parrentCategory){
            if(parrentCategory.seo_text){
                setSeoText(parrentCategory.seo_text)
            }
        }
    }, [category, parrentCategory, thirdCategory, fourthCategory, parrent])

    const googleAnalyticCategory = useMemo(() => {
        if(!category && !parrent)
            return {
                item_list_id: 0,
                item_list_name: 'Каталог',
            }

        if(!category && parrent)
            return {
                item_list_id: parrent,
                item_list_name: parrentTitles[parrent],
            }

        return {
            item_list_id: `${parrent}-${category.id}`,
            item_list_name: category.name,
        }
    }, [category, parrent])

    const observeProduct = useCallback((product, ref, selectSize) => {
        totalProductsCount++
        const customProps = {
            index: totalProductsCount,
            ...googleAnalyticCategory,
        }
        if (product.child_product_size) {
            let findSize = product.child_product_size.find(element => element.size_name === selectSize);
            if (findSize) {
                let offerId = String(product.id)+String(product.color.id)+findSize.id;
                googleAnalyticProducts[offerId] = {...product, currentSize: selectSize, customProps}
                productsObserver.observe(ref)
            }
        }
    }, [googleAnalyticCategory])


    useEffect(() => {
        pushGoogleAnalyticViewItemList()

        return pushGoogleAnalyticViewItemList
    }, [category, parrent])

    const [catalogModelView, setCatalogModelView] = useState('standart');


    const setFilterParam = (value) => {
        navigate({pathname: '.', search: `?${newQueryString('sort', value)}`});
        sort = value
    }

    const newQueryString = (key, value) => {
        let searchParams = new URLSearchParams(location.search);
        if (key === 'page') {
            if (value < 2) {
                searchParams.delete(key);
            } else {
                searchParams.set(key, value);
            }
        } else {
            if (value == 'default') {
                searchParams.delete(key)
            } else {
                searchParams.set(key, value);
            }
        }
        return searchParams.toString()
    };

    const getAlterTitleAndDesc = () => {
        if (parrent === "bestseller") {
            if (category) {
                return (
                    <Helmet>
                        <title>Бестселлеры, {category.name} - купить в Москве в интернет-магазине YOU WANNA {pageSuffix}</title>
                        {
                            category.is_secret ? 
                                <meta name="robots" content="noindex, nofollow"/>
                            : ''
                        }
                        <meta name="description"
                            content={"Бестселлеры, " + category.name + " для женщин в интернет-магазине YOU WANNA. По имеющимся вопросам звоните: ☎ +7 (926) 887-70-07" + pageSuffix}  />
                        <link rel='canonical' href={process.env.REACT_APP_API_URL.slice(0, -1)+location.pathname}/>
                    </Helmet>
                )
            } else {
                return (
                    <Helmet>
                        <title>Бестселлеры женской одежды - купить в Москве в интернет-магазине YOU WANNA {pageSuffix}</title>
                        <meta name="description"
                            content={"Бестселлеры одежды в интернет-магазине YOU WANNA. По имеющимся вопросам звоните: ☎ +7 (926) 887-70-07" + pageSuffix}/>
                        <link rel='canonical' href={process.env.REACT_APP_API_URL.slice(0, -1)+location.pathname}/>
                    </Helmet>
                )
            }
        }
        if (parrent === "coming_soon") {
            if (category) {
                return (
                    <Helmet>
                        <title>Скоро в наличии {category.name} в интернет-магазине YOU WANNA {pageSuffix}</title>
                        {
                            category.is_secret ? 
                                <meta name="robots" content="noindex, nofollow"/>
                            : ''
                        }
                        <meta name="description"
                            content={"Скоро в наличии " + category.name + " для женщин в интернет-магазине YOU WANNA. По имеющимся вопросам звоните: ☎ +7 (926) 887-70-07" + pageSuffix}/>
                        <link rel='canonical' href={process.env.REACT_APP_API_URL.slice(0, -1)+location.pathname}/>
                    </Helmet>
                )
            } else {
                return (
                    <Helmet>
                        <title>Скоро в наличии в интернет-магазине YOU WANNA {pageSuffix}</title>
                        <meta name="description"
                            content={"Скоро в наличии в интернет-магазине YOU WANNA. По имеющимся вопросам звоните: ☎ +7 (926) 887-70-07" + pageSuffix}/>
                        <link rel='canonical' href={process.env.REACT_APP_API_URL.slice(0, -1)+location.pathname}/>
                    </Helmet>
                )
            }
        }

        if (parrent == "new-collection") {
            if (category) {
                return (
                    <Helmet>
                        <title>Новинки в категории {category.name} - купить в Москве в интернет-магазине YOU WANNA {pageSuffix}</title>
                        {
                            category.is_secret ? 
                                <meta name="robots" content="noindex, nofollow"/>
                            : ''
                        }
                        <meta name="description"
                            content={"Новинки в категории " + category.name + " в интернет-магазине YOU WANNA. По имеющимся вопросам звоните: ☎ +7 (926) 887-70-07" + pageSuffix}/>
                        <link rel='canonical' href={process.env.REACT_APP_API_URL.slice(0, -1)+location.pathname}/>
                    </Helmet>
                )
            } else {
                return (
                    <Helmet>
                        <title>Новинки женской одежды - купить в Москве в интернет-магазине YOU WANNA {pageSuffix}</title>
                        <meta name="description"
                            content={"Новинки одежды в интернет-магазине YOU WANNA. По имеющимся вопросам звоните: ☎ +7 (926) 887-70-07" + pageSuffix}/>
                        <link rel='canonical' href={process.env.REACT_APP_API_URL.slice(0, -1)+location.pathname}/>
                    </Helmet>
                )
            }
        }
        if (parrent === "sale") {
            if (category) {
                return (
                    <Helmet>
                        <title>Распродажа в категории {category.name} купить по скидке в интернет-магазине YOU WANNA {pageSuffix}</title>
                        {
                            category.is_secret ? 
                                <meta name="robots" content="noindex, nofollow"/>
                            : ''
                        }
                        <meta name="description"
                            content={"Распродажа в категории " + category.name + " в интернет-магазине YOU WANNA. По имеющимся вопросам звоните: ☎ +7 (926) 887-70-07" + pageSuffix}/>
                        <link rel='canonical' href={process.env.REACT_APP_API_URL.slice(0, -1)+location.pathname}/>
                    </Helmet>
                )
            } else {
                return (
                    <Helmet>
                        <title>Скидки на женскую одежду купить в Москве в интернет-магазине YOU WANNA {pageSuffix}</title>
                        <meta name="description"
                            content={"Распродажа одежды в интернет-магазине YOU WANNA, большие скидки! По имеющимся вопросам звоните: ☎ +7 (926) 887-70-07" + pageSuffix}/>
                        <link rel='canonical' href={process.env.REACT_APP_API_URL.slice(0, -1)+location.pathname}/>
                    </Helmet>
                )
            }
        }
    }

    const handleScroll = () => {
        if(window.scrollY)
            settings.setScrollStatusCatalog(window.scrollY);
    };

    const getAlterTitleAndDescHitCategori = () => {
        if(!parrentCategory || !parrent ) {
            return (
                <Helmet>
                    <title>Каталог {pageSuffix}</title>
                    <meta name="description" content={"Каталог интернет-магазина YOU WANNA. По имеющимся вопросам звоните: ☎ +7 (926) 887-70-07"  +
                    pageSuffix} />
                    <link rel='canonical' href={process.env.REACT_APP_API_URL.slice(0, -1)+location.pathname}/>
                </Helmet>
            )
        }
        if(category && parrentCategory || category) {
            if (category.title) {
                return (
                    <Helmet>
                        <title>{category.title} {pageSuffix}</title>
                        {
                            category.description ?
                                <meta name="description"
                                content={category.description + pageSuffix}/>
                            :
                                <meta name="description"
                                    content={"Купить дизайнерские " + category.name.toLowerCase() +
                                        " в интернет-магазине YOU WANNA. ✔ Каталог с оригинальными и стильными товарами. Доставка по Москве и России. Для заказа звоните по телефону: ☎ +7 (926) 887-70-07" +
                                    pageSuffix}/>
                        }
                        {
                            category.is_secret ?
                                <meta name="robots" content="noindex, nofollow"/>
                            : ''
                        }
                        <link rel='canonical' href={process.env.REACT_APP_API_URL.slice(0, -1) + location.pathname}/>
                    </Helmet>
                )
            } else {
                return (
                    <Helmet>
                        <title>{category.name} - купить в Москве в интернет-магазине YOU WANNA {pageSuffix}</title>
                        {
                            category.description ?
                                <meta name="description"
                                content={category.description + pageSuffix}/>
                            :
                                <meta name="description"
                                    content={"Купить дизайнерские " + category.name.toLowerCase() +
                                    " в интернет-магазине YOU WANNA. ✔ Каталог с оригинальными и стильными товарами. Доставка по Москве и России. Для заказа звоните по телефону: ☎ +7 (926) 887-70-07" +
                                    pageSuffix}/>
                        }
                        {
                            category.is_secret ?
                                <meta name="robots" content="noindex, nofollow"/>
                            : ''
                        }
                        <link rel='canonical' href={process.env.REACT_APP_API_URL.slice(0, -1) + location.pathname}/>
                    </Helmet>
                )
            }
        }

        if (parrentCategory && !category && parrentCategory !== "bestseller" && parrentCategory !== "new-collection" && parrentCategory !== "coming_soon") {
            if (parrentCategory.title) {
                return (
                    <Helmet>
                        <title>{parrentCategory.title} {pageSuffix}</title>
                        {
                            parrentCategory.is_secret ?
                                <meta name="robots" content="noindex, nofollow"/>
                            : ''
                        }
                        <meta name="description"
                            content={"Купить дизайнерский " + parrentCategory.name.toLowerCase() +
                                " в интернет-магазине YOU WANNA. ✔ Каталог с оригинальными и стильными товарами. Доставка по Москве и России. Для заказа звоните по телефону: ☎ +7 (926) 887-70-07"  +
                                pageSuffix}/>
                        <link rel='canonical' href={process.env.REACT_APP_API_URL.slice(0, -1)+location.pathname}/>
                    </Helmet>
                )
            } else {
                return (
                    <Helmet>
                        <title>{parrentCategory.name} - купить в Москве в интернет-магазине YOU WANNA {pageSuffix}</title>
                        {
                            parrentCategory.is_secret ?
                                <meta name="robots" content="noindex, nofollow"/>
                            : ''
                        }
                        <meta name="description"
                            content={"Купить дизайнерский " + parrentCategory.name.toLowerCase() +
                                " в интернет-магазине YOU WANNA. ✔ Каталог с оригинальными и стильными товарами. Доставка по Москве и России. Для заказа звоните по телефону: ☎ +7 (926) 887-70-07"  +
                                pageSuffix}/>
                        <link rel='canonical' href={process.env.REACT_APP_API_URL.slice(0, -1)+location.pathname}/>
                    </Helmet>
                )
            }
        }
    }



    const [transitionButton, setTransitionButton] = useState(false)
    const [seoText, setSeoText] = useState(false)
    const allPages = Math.ceil(count / limit)





    useEffect(() => {
        if (allPages > 1) {
            setTransitionButton(true)
        } else {
            setTransitionButton(false)
        }

    }, [count])


    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }
    const prevCats = usePrevious({parrent, child})

    const linkNewPage = (count, limit, page) => {
        if (!count || !limit) {
            setTransitionButton (false)
        }

        if(!page || page <= 0) {
            setTransitionButton (true)
        }

        if(count <= limit) {
            setTransitionButton (false)
        }
    }
    useEffect(() => {
        fetchFilterContext(parrent, child, third, fourth, catalogFakeCategory).then(response => {
            if (response && response.data) {
                if (response.data.hide) {
                    setShowFilters(false);
                    setFilters({colors: [], sizes: [], trends: [], price_current: []})
                    return
                } else {
                    setShowFilters(true);
                }
                setFilters({...response.data, price_current: [response.data.price_min, response.data.price_max], filter_price: [response.data.price_min, response.data.price_max]})
            }
        })
    }, [parrent, child])

    useEffect(() => {
        let needFilter = true;
        let second = true;
        window.scrollTo(0, 0);
        setGetNewProductsActive(false)

        if (parrent) {
            getCategory(parrent).then(response => {
                if (response.success) {
                    setParrentCategory(response.data)
                    setActualCat(response.data);
                } else {
                    setParrentCategory(null)
                }
            })
        }

        if (child) {
            getCategory(child).then(response => {
                if (response.success) {
                    setCategory(response.data);
                    setActualCat(response.data);
                }
            })
        } else {
            setCategory(false)
        }

        if (third) {
            getCategory(third).then(response => {
                if (response.success) {
                    setCategory(response.data);
                    setThirdCategory(response.data);
                    setActualCat(response.data);
                }
            })
        } else {
            setThirdCategory(false)
        }

        if (fourth) {
            getCategory(fourth).then(response => {
                if (response.success) {
                    setCategory(response.data);
                    setFourthCategory(response.data);
                    setActualCat(response.data);
                }
            })
        } else {
            setFourthCategory(false)
        }


        if (prevCats && prevCats.parrent !== parrent) {
            second = false
        }
        if (prevCats && prevCats.child !== child) {
            second = false
        }
        if (!second && applyFilter) {
            needFilter = false;
            setApplyFilter(0);
        }
        if (!applyFilter) {
            needFilter = false;
        }
        fetchProducts(parrent, child, third, fourth, catalogFakeCategory, limit, page, sort, needFilter ? appliedFilters : null, true).then(response => {
            setSpiner(false)
            if (response && response.status == 404) {
                setNotFound(true);
                return
            }
            if (!response.data.error) {
                if (response.length >= limit) {
                    setGetNewProductsActive(true)
                }
                if (response.length < 1) {
                    setGetNewProductsActive(false)
                }

                setProductsArray(response.data.rows)
                setCount(response.data.count)

            }
            if (response.data.error) {
                setPageCatalogError(response.error)
                setProductsArray([])
                setCount(0)
            }
        })
        getDataForBreadcrumbs(parrent, child, third, fourth, catalogFakeCategory).then(response => {
            setParamsForBreadcrumbs(response)
        })

    }, [parrent, child, sort, page, applyFilter])


    useEffect(() => {
        setDisabledFilterBtn(true);
        countFilterdProducts(parrent, child, third, fourth, catalogFakeCategory, filters).then(response => {
            if (response && response.status == 404) {
                setCountFiltered(0)
            }
            setCountFiltered(response.data.count)
        }).finally(() => {
            setDisabledFilterBtn(false);
        })
    }, [filters.colors, filters.sizes, filters.trends, filters.price_current])

    const setFilteredProducts = () => {
        setaAppliedFilters({
            colors: filters.colors.filter(item => item.checked),
            sizes: filters.sizes.filter(item => item.checked),
            trends: filters.trends.filter(item => item.checked),
            price_current: filters.price_current
        })
        if (page > 1) {
            setPage(1)
            setApplyFilter(applyFilter+1)
        } else {
            setApplyFilter(applyFilter+1)
        }
    }

    const setPage = (value) => {
        navigate({pathname: '.', search: `?${newQueryString('page', value)}`});
    }

    if (notFound) {
        return (
            <NotFound/>
        )
    }

    const resetFilters = () => {
        setApplyFilter(0);
    }


    return (
        <FilterContext.Provider value={{filters, setFilters, setFilteredProducts, countFiltered, resetFilters, disabledFilterBtn}}>
        <>
            {(
                window.location.pathname.includes("catalog/collab/youwannarollton")
            ) && (
                <NoodlesRain/>
            )}
            <div style={{marginTop: settings.headerHeight}} className={"catalog_section"}>
                {notFound ? <NotFound/> : null}

                {
                    parrent !== "bestseller" && parrent !== "new-collection" && parrent !== "coming_soon" && parrent !== "sale"  ?
                        getAlterTitleAndDescHitCategori()  :  getAlterTitleAndDesc()
                }
                <div className={"filter_and_catalog"}>
                    {
                        productsArray[0] ?
                            <div className={"breadcrmbs_catalog"}>
                                <BreadcrumbsCatalog
                                    breadcrumbsParams={paramsForBreadcrumbs}
                                />
                            </div>
                            : ''
                    }
                    {
                        actualCat.banner ?
                            <div className={'banner_container'}>
                                <img className={'banner_img'} src={
                                    process.env.REACT_APP_API_URL+"uploads/images/categories/" + actualCat.banner}
                                        alt={(actualCat.name ? actualCat.name : '')}
                                />
                            </div>
                        : ''
                    }
                    {
                        productsArray[0] ?
                            <BreadcrmbsAndFilterLine
                                setCatalogModelView={setCatalogModelView}
                                catalogModelView={catalogModelView}
                                currentStateCatalogModelView={catalogModelView}
                                // arrayStateSort={arrayStateSort}
                                filterParam={sort}
                                // setFilterParam={e => settings.setStateFilerCatalog(e)}
                                setFilterParam={e => setFilterParam(e)}
                                thirdCategory={thirdCategory}
                                fourthCategory={fourthCategory}
                                category={category}
                                parrentCategory={parrentCategory}
                                parrent={parrent}
                                showFilters={showFilters}
                            />
                            :
                            ''
                    }

                    {
                        spiner ?
                            <div className={"products_wrapper " + catalogModelView}>
                                <Spiner/>
                            </div>
                            :
                            <div className={"products_wrapper " + catalogModelView}>
                                {
                                    productsArray[0] ? productsArray.map((item, index) => {
                                            if (index === productsArray.length-1 && settings.scrollStatusCatalog) {
                                                window.scrollTo(0, settings.scrollStatusCatalog)
                                            }
                                            return (<OneProductCart
                                                observeProduct={observeProduct}
                                                item={item}
                                                index={index}
                                                key={item.id}
                                                parrent={parrent}
                                                googleAnalyticCategory={googleAnalyticCategory}
                                                child={child}
                                                handleScroll={e => handleScroll()}
                                            />)
                                        }
                                    ) : <div className={"no_find_product"}>Нет товаров</div>
                                }
                            </div>
                    }

                </div>

                {
                    transitionButton && settings.projectWidth && settings.projectWidth < 1024 ?
                        <Link
                            onClick={e => linkNewPage(count, limit, page)}
                            to={page == allPages ? `?${newQueryString('page', parseInt(page) - 1)}` : `?${newQueryString('page', parseInt(page) + 1)}`} className="give_next_products"><span>{page != allPages ? "Показать еще" : "Вернуться назад" }</span></Link>
                        : ''
                }

                <Pagination page={parseInt(page)} limit={limit} count={count}/>

                {
                    seoText ?
                        <div className="seo_text" dangerouslySetInnerHTML={{__html: seoText}}/>
                            : ""

                }
            </div>
        </>
        </FilterContext.Provider>
    );
});

export default Catalog;
