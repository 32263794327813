import {$authHost,$host} from "./index";
import jwt_decode from "jwt-decode";



export const registration = async (telephone,country,token,deviceUUID,userAgent) => {
    const response = await $host.post('api/user/registration', {telephone,country,token,deviceUUID,userAgent})
    return response;
}


export const login = async (telephone, smspass, deviceUUID) => {
    const {data} = await $host.post('api/user/smsLogin', {telephone, smspass, deviceUUID})

    if (!data.token) {
        return data;
    }
    localStorage.setItem('token', data.token)

    return jwt_decode(data.token);
}


export const check = async () => {
    const {data} = await $authHost.get('api/user/auth')
    if(data.token) {
        localStorage.setItem('token', data.token)
        return jwt_decode(data.token);
    }

}


export const changeNumberGetCode = async (newNumber, lengthNumber, currentUser, deviceUUID) => {
    const data = await $authHost.post('api/user/changenumber', {newNumber, lengthNumber, currentUser, deviceUUID})
    if(data) {
        return data;
    }
    return false;
}

export const isComparePassword = async (code, userId, newNumber, newCountry) => {
    const {data} = await $authHost.post('api/user/comparepassword', {code, userId, newNumber, newCountry})
    if(data.token) {
        localStorage.setItem('token', data.token)
        return {jwt: jwt_decode(data.token), error: ''};
    }

    return {error: data.error};
}

export const saveUserInfo = async (userFields,user,deviceUUID,userAgent) => {

    const {data} = await $authHost.post('api/user/saveuserinfo',{userFields,user,deviceUUID,userAgent})

    return data;

}

export const adminLogin = async (userLogin,password) => {
    const {data} = await $authHost.post('api/user/adminlogin',{userLogin,password})
    if(data.token) {
        localStorage.setItem('token', data.token)
        return jwt_decode(data.token);
    }
    return data;
}


export const getOrdersByUserId = async (userId) => {
    const {data} = await $authHost.post('api/user/getordersbyuserid',{userId})
    return  data;
}

export const fetchAllUsers = async (limit,offset,getCount,searchRoleParam,searchQuery) => {
    const {data} = await $authHost.post('api/user/fetchallusers',{limit,offset, getCount,searchRoleParam,searchQuery});

    return data;
}
export const saveAdmUser = async (userField) => {
    const {data} = await $authHost.post('api/user/saveadmuser',{userField});

    return data;
}

export const parserUserCsv = async (sortField,sortParam) => {
    const data = await $authHost.post('api/user/parseuserscsv',{sortField,sortParam})
    return data;
}

export const findUserByNumber = async (number) => {
    const {data} = await $host.post('api/user/finduserbyid',{number})
    return data;
}

export const getСheckingUserOrder = async (userId) => {
    const {data} = await $authHost.post('api/user/getcheckinguserorder',{userId})
    return  data;
}

export const getLoyaltyInfo = async (userId, page = 1) => {
    const {data} = await $authHost.post('api/user/getloyaltyinfo',{userId, page})
    return  data;
}

export const unbanuser = async (id) => {
    const {data} = await $authHost.post('api/user/unbanuser',{id})
    return  data;
}

export const fetchBannedUsers = async (limit,offset,getCount,searchRoleParam,searchQuery) => {
    const {data} = await $authHost.post('api/user/fetchbannedusers',{limit, offset, getCount, searchQuery});

    return data;
}