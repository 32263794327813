import React, {useContext, useEffect, useRef, useState, useLayoutEffect} from 'react';
import {
    mindboxSetCart,
    priceToUserString
} from "../../http/basketApi";
import {Context} from "../../index";
import {observer} from "mobx-react-lite";
import {Link, useNavigate} from "react-router-dom";
import Like from "../../components/UI/Like/Like";
import {pushGoogleAnalyticProductAction} from "../../utils/googleAnalytic";
import { LazyLoadImage } from "react-lazy-load-image-component";

const OneProductCart = observer((props) => {
    const {settings, basket} = useContext(Context);

    const basketPullItems = (basketStorage) => {
        return basketStorage ? JSON.parse(basketStorage).map(function(basketItem) {
            return {
                id: basketItem.item.id,
                size: basketItem.currentSize,
                colorId: basketItem.colorId || basketItem.item.colorId || basketItem.item.color.id,
                count: basketItem.count,
                pricePerItem: basketItem.item.parrent_price
            };
        }) : []
    }

    const [activeChild,setActiveChild] = useState({})
    const [imageVision,setImageVision] = useState("");
    const [selectSize,setSelectSize] = useState("");
    const [textButton,setTextButton] = useState('');
    const [nameProduct, setNameProduct] = useState('')
    const [deviceUUID, setDeviceUUID] = useState('')
    const [userAgent, setUserAgent] = useState('')
    const targetRef = useRef();
    const [containerWidth, setContainerWidth] = useState(0);

    useEffect(() => {
        setDeviceUUID(document.cookie.replace(/(?:(?:^|.*;\s*)mindboxDeviceUUID\s*\=\s*([^;]*).*$)|^.*$/, "$1"))
        setUserAgent(window.navigator.userAgent)
    })

    const sendGoogleAnalytic = (action) => {
        if(!activeChild || !activeChild.id || !props.googleAnalyticCategory) return;
        pushGoogleAnalyticProductAction(action, [{...activeChild, currentSize: selectSize, customProps: {index: props.index, ...props.googleAnalyticCategory}}] )
    }

    function setSizeDefault(item) {

        let filterSizes = item.child_product_size_field.filter(item => item.isActive !== false );
        if(filterSizes[0]) {
            setNonActiveButtonAddProduct(true)
            if (!filterSizes[0].available) {
                setNonActiveButtonAddProduct(false);
            }
            setSelectSize(filterSizes[0].size_name);
            return false;
        }
        setNonActiveButtonAddProduct(false)
        setSelectSize("");
    }
    const [nonActiveButtonAddProduct, setNonActiveButtonAddProduct] = useState(false)

    useLayoutEffect(() => {
        if (targetRef.current) {
            setContainerWidth(targetRef.current.offsetWidth);
        }
    }, []);

    useEffect(() => {

        if (!props.item.product) {
            return false;
        }

        const reduceItem = (props.item.product.childProducts && props.item.product.childProducts.length) ? [...props.item.product.childProducts].filter((a,b) => a.id === props.item.id) : [];
        if(reduceItem[0]){
            setActiveChild(reduceItem[0]);
            setImageVision(reduceItem[0].img_preview);
            setSelectSize(reduceItem[0].child_product_size_field[0].size_name);
            if (reduceItem[0].child_product_size_field[0].available != false) {
                setNonActiveButtonAddProduct(true)
            }
            let str = reduceItem[0].name.toLowerCase();
            let a = str.split('');
            a[0] = a[0].toUpperCase();
            let i = 0;
            let s = '';
            while (i < a.length) {
                s = s + a[i]
                i++;
            };
            setNameProduct(s)
            setSizeDefault(reduceItem[0])
            return s;
        }
    },[])

    const onHoverChangeImage = () => {
        setImageVision(activeChild.img_hover)
    }
    const onMouseLeaveHandler = () => {
        setImageVision(activeChild.img_preview)
    }
    const clickColorHandler = (item) => {
        setActiveChild(item)
        setImageVision(item.img_hover)
        setSizeDefault(item);
    }

    function giveClassName(item) {
        if(item.available === false) {
            if (item.size_name === selectSize) {
                return("one_color_selector disactive active");
            }
            return("one_color_selector disactive");
        }
        if(item.size_name === selectSize) {
            return("one_color_selector active");
        }

        return("one_color_selector")
    }

    const changeSizeProduct = (item) => {
        if(item.available === false) {
            setSelectSize(item.size_name);
            setNonActiveButtonAddProduct(false)
            return false
        }
        setSelectSize(item.size_name);
        setNonActiveButtonAddProduct(true)
    }

    const buildOfferId = () => {
        if (activeChild && selectSize && activeChild.child_product_size) {
            let findSize = activeChild.child_product_size.find(item => item.size_name === selectSize)
            if (findSize) {
                return String(activeChild.id)+String(activeChild.color.id)+findSize.id
            }
        }
    }
    const addToBasketHandler = async (item, currentSize) => {
        basket.addProduct(item, currentSize)
        mindboxSetCart(deviceUUID, userAgent, basketPullItems(window.localStorage.getItem('basketStorage')))
    }

    useEffect(() => {
        let isExistElement = false;

        if (basket.basketItems && activeChild && selectSize) {
            basket.basketItems.forEach((itemBasket,index) => {
                if(itemBasket.item.id === activeChild.id && activeChild.colorId === itemBasket.colorId && itemBasket.currentSize === selectSize) {
                    isExistElement = true
                }
            })
            if(activeChild.coming_soon) {
                setTextButton("Оформить предзаказ");
                return false;
            }
            if(isExistElement) {
                setTextButton("Добавить еще")
            } else {
                setTextButton("Добавить в корзину")
            }
        }

    },[basket.basketItems,selectSize,activeChild])

    function getOneProductUrl() {
        if(!activeChild.product) {
            return false;
        }

        if(activeChild.product.product_url) {
            return activeChild.product.product_url+activeChild.code+"/";
        }

        var url = "/catalog/";
        if(props.parrent){
            url += props.parrent+"/";
        }
        if(props.child){
            url += props.child+"/";
        }
        url += "product/"+activeChild.code+"/";
        return url;

    }

    const Navigate = useNavigate();

    const preOrderHandler = (item) =>  {
        Navigate(getOneProductUrl());
    }

    const ref = useRef()

    // ref
    useEffect(() => {
        if(activeChild && selectSize && activeChild.id && ref && props.observeProduct)
            props.observeProduct(activeChild, ref.current, selectSize)
    }, [ref, activeChild, selectSize])

    const [displayingColorName,setDisplayingColorName] = useState("");
    const [colorNameClickMob,setColorNameClickMob] = useState("");

    const onHoverNameColorChange = (item) => {
        if (item.color.color_name) {
            setDisplayingColorName(item.color.color_name)
        }
    }
    const onMouseNameColorHandler = () => {
        setDisplayingColorName('')
    }

    const outputСolorName = (item) => {
        if (item.color.color_name) {
            setColorNameClickMob(item.color.color_name)
        }
    }

    return (
        <div
            onMouseEnter={e => onHoverChangeImage()}
            onMouseLeave={e => onMouseLeaveHandler()}
            className= { (window.location.pathname != '/personal/favorites/' ? "one_product_wrapper" : "one_product_wrapper favorite") + (props.className ? ` ${props.className}` : "")}>

            <div className="one_product">
                <div className="image_container">
                    {
                        activeChild && !!activeChild.sell_value && (
                            <span className="sale__label">-{activeChild.sell_value}%</span>
                        )
                    }
                    <div className={"dop_controll_bar_product"}>
                        <div className={"sticker_product"}>
                            {activeChild.limited ? 
                                <div className={"one_stiker"}>
                                    <img className="one_stiker_limited" src="/files/images/limit-logo.png" width={26} height={16} alt=""/>
                                    Limited
                                </div>
                                : ""
                            }

                            {
                                activeChild.sell_price != 0 ?
                                    <>
                                        {/* <div className={"one_stiker _black_friday"}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                <path d="M11.2694 5.84883H8.62393L9.8147 2H6.17185L4.73065 8.8958H6.49498L6.04594 14L11.2694 5.84883Z" fill="white"/>
                                            </svg>
                                            Black Friday
                                        </div> */}
                                        <div className={"one_stiker"}>Sale</div>
                                    </>
                                    : ''
                            }

                            {
                                new Date(activeChild.createdAt).getTime() >= (new Date()).getTime() - 30*24*60*60*1000 ?
                                    <div className={"one_stiker one_stiker_new"}>New</div>
                                    :
                                    ''
                            }



                            {
                                activeChild.exclusive ? 
                                    <div className={"one_stiker"}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                            <path d="M13.3404 2H8.97872L11.1596 5.4867L13.3404 2ZM5.84043 5.4867L8.02128 2H3.65957L5.84043 5.4867ZM6.31915 5.7234H10.6809L8.5 2.2367L6.31915 5.7234ZM6.20213 6.25532L8.5 13.609L10.7979 6.25532H6.20213ZM11.3537 6.25532L9.07447 13.5559L15.867 6.25532H11.3537ZM13.8191 2.2367L11.6383 5.7234H16L13.8191 2.2367ZM1 5.7234H5.3617L3.18085 2.2367L1 5.7234ZM5.64628 6.25532H1.1383L7.875 13.3883L5.64628 6.25532Z" fill="black"/>
                                        </svg>
                                        Exclusive
                                    </div>
                                : ""
                            }

                            {
                                activeChild.haute_couture ? 
                                <div className={"one_stiker"}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                        <path d="M10.8346 7.97696C11.2758 7.97696 11.7531 7.80534 12.2184 7.48115C12.2328 7.47056 12.2497 7.45996 12.2642 7.44725C12.0038 7.01077 11.8592 6.5192 11.864 5.95982C12.0162 5.89791 12.145 5.79893 12.2352 5.67462C12.3253 5.55032 12.3731 5.4059 12.3727 5.25849C12.3727 4.83048 11.9797 4.48511 11.4951 4.48511C11.0082 4.48511 10.6128 4.83048 10.6128 5.25849C10.6128 5.53393 10.7767 5.77548 11.0226 5.91109C10.8298 6.42385 10.3476 6.92813 9.68946 7.42182C9.69188 7.4303 9.69911 7.43666 9.70393 7.44301C9.98117 7.78203 10.3645 7.97696 10.8346 7.97696ZM4.13743 5.95982C4.14225 6.50437 4.00484 6.98322 3.75653 7.41123C3.77581 7.42606 3.79269 7.44089 3.81197 7.45361C4.28931 7.79686 4.78111 7.97908 5.23434 7.97908C5.338 7.97908 5.43925 7.96849 5.53568 7.94942C5.85391 7.88585 6.12392 7.72482 6.3433 7.46844C6.34571 7.4642 6.35053 7.45996 6.35294 7.45573C5.6731 6.9472 5.17407 6.43232 4.97638 5.91109C5.10203 5.84176 5.20557 5.7455 5.27726 5.63134C5.34895 5.51718 5.38644 5.38888 5.38622 5.25849C5.38622 4.83048 4.99085 4.48511 4.50387 4.48511C4.0193 4.48511 3.62634 4.83048 3.62634 5.25849C3.62634 5.56996 3.83608 5.83905 4.13743 5.95982Z" fill="black"/>
                                        <path d="M14.7601 4.48522C14.2322 4.48522 13.8054 4.86025 13.8054 5.32428C13.8054 5.56159 13.9163 5.77559 14.0972 5.93027C13.7066 6.7206 13.1449 7.37744 12.5181 7.81392C11.8503 8.28006 11.1126 8.49407 10.4304 8.3627C9.9723 8.27159 9.59622 8.0364 9.31416 7.69103C8.95736 7.25666 8.74762 6.65067 8.72111 5.95781C8.8789 5.85796 9.00734 5.72649 9.0958 5.57431C9.18426 5.42213 9.23019 5.25362 9.22978 5.08273C9.22978 4.48522 8.67771 4 7.99787 4C7.31803 4 6.77078 4.48522 6.77078 5.08273C6.77078 5.47048 6.99981 5.80949 7.34696 6.00019C7.31562 6.69093 7.10106 7.2948 6.7298 7.72281C6.45256 8.04911 6.08612 8.27583 5.64253 8.3627C4.94582 8.50042 4.18642 8.26947 3.50658 7.78002C2.88942 7.33294 2.33735 6.67398 1.95403 5.88365C2.07661 5.76252 2.15669 5.61281 2.18463 5.45257C2.21256 5.29233 2.18715 5.12841 2.11146 4.98058C2.03577 4.83275 1.91303 4.70732 1.75806 4.61943C1.60308 4.53153 1.42248 4.48492 1.23803 4.48522C0.71007 4.48522 0.285772 4.86025 0.285772 5.32428C0.285091 5.507 0.352298 5.68493 0.47718 5.83102C0.602063 5.97711 0.777792 6.08338 0.977667 6.13368L2.97862 11.363H13.0195L15.0229 6.13156C15.2441 6.0757 15.435 5.95152 15.5603 5.78184C15.6856 5.61216 15.7371 5.40842 15.7051 5.20808C15.6731 5.00774 15.5599 4.82429 15.3863 4.69147C15.2126 4.55866 14.9903 4.48541 14.7601 4.48522ZM2.06276 11.787H13.9376V13H2.06276V11.787Z" fill="black"/>
                                    </svg>
                                    Haute couture
                                </div>
                                : ""
                            }

                            {
                                !nonActiveButtonAddProduct?
                                    <div className={"one_stiker one_stiker_new"}>Sold out</div>
                                    :
                                    ''
                            }
                        </div>
                        <div className="add_favorite_product">
                            <Like
                                onAddInFavorite={() => sendGoogleAnalytic('add_to_wishlist')}
                                itemProduct={{...props.item, id: activeChild.id, color: activeChild.color}}
                            />
                        </div>
                    </div>
                    <div onClick={e => {props.handleScroll();sendGoogleAnalytic('select_item')}}>
                        <Link ref={targetRef} className={"link_product_page"} to={getOneProductUrl()}>
                            {
                                imageVision ?
                                    <LazyLoadImage className={"standart_photo_product"} src={
                                        process.env.REACT_APP_API_URL+"uploads/images/"+activeChild.productId+"/"+activeChild.id+"/" + "950_"+ imageVision}
                                         alt={'YouWanna - ' +(nameProduct ? nameProduct : '')}
                                    /> : ""
                            }
                        </Link>
                    </div>
                </div>

                <div className={window.location.pathname != '/personal/favorites/'  ? "container_wrapper_product" : "container_wrapper_product favorites"}>
                    <div className={"previews_info_product_wrap"}>
                        <Link className={"bottom_one_product_wrapper"} to={getOneProductUrl()} onClick={() => sendGoogleAnalytic('select_item')}>
                            <div className={"name_wrapper_product"} ref={ref} data-id={buildOfferId()}>
                                <span className={"name_product_text"}>{nameProduct ? nameProduct : ""}</span>
                            </div>
                        </Link>

                        {
                            !activeChild.price_hidden ?
                                <Link className={"bottom_one_product_wrapper"} to={getOneProductUrl()} onClick={() => sendGoogleAnalytic('select_item')}>
                                        <div className={"price_product_wrapper"}>
                                            <span className={"sell_price_product"}> {activeChild.sell_price ? priceToUserString(activeChild.sell_price)+" ₽" : ""}  </span>
                                            <span className={activeChild.sell_price ? "price_product old_price" : "price_product"}>{activeChild.product ? priceToUserString(activeChild.product.price)+" ₽" : "no price"} </span>
                                        </div>
                                </Link> 
                            : ''
                        }

                            <div className={"bottom_one_product_wrapper"}>
                                <div className={"color_palette_product"}>
                                    {
                                        settings.projectWidth && settings.projectWidth > 1024 ?
                                            <div className={"name_color_hover"}>{displayingColorName}</div>
                                        :
                                            <div className={"name_color_hover"}>{colorNameClickMob}</div>
                                    }

                                    <div className="color_picker_product previews">
                                        {
                                            (props.item.product && activeChild) ? props.item.product.childProducts.map((item,index) => {
                                                    if(item.is_active) {
                                                        return(
                                                            <div
                                                                onMouseEnter={e => onHoverNameColorChange(item)}
                                                                onMouseLeave={e => onMouseNameColorHandler()}
                                                                onClick={e => outputСolorName(item)}
                                                                key={index}
                                                                className={activeChild.colorId === item.color.id ? "circle_colorpiker_border active" : "circle_colorpiker_border"}>
                                                                <div style={item.color.img ? {backgroundImage: "url("+process.env.REACT_APP_API_URL+"uploads/images/colors/"+item.color.id+"/"+item.color.img+")"} : {backgroundColor: item.color.hex}} key={item.id}
                                                                        onClick={e => clickColorHandler(item)}
                                                                        className={ item.color.hex === 'white' || item.color.hex == '#ffffff' ? "circle_colorpiker_wrapper active" : "circle_colorpiker_wrapper" }>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                }
                                            )
                                            : ""
                                        }
                                    </div>
                                </div>
                                <div className="size_picker_product">
                                    {
                                        activeChild.child_product_size_field ?
                                            activeChild.child_product_size_field.map((item, index) => {
                                                if(item.isActive) {
                                                    return(
                                                        <div key={item.size_name}
                                                                onClick={e => changeSizeProduct(item)}
                                                                className={giveClassName(item)}>
                                                            {item.size_name}
                                                        </div>
                                                    )
                                                }
                                            })  
                                        : ""
                                    }
                                </div>
                                
                            </div>
                    </div>
                    {
                        window.location.pathname != '/personal/favorites/' && !props.item.product.electron_sertificate ?
                            <div className={"controll_panel_offers_and_colors"}>
                                {nonActiveButtonAddProduct ?
                                    <div
                                        onClick={e => activeChild.coming_soon ? preOrderHandler(activeChild) : addToBasketHandler(activeChild, selectSize)}
                                        className="add_product_to_cart"
                                    >
                                        {textButton}
                                    </div>
                                :
                                    <div
                                        onClick={e => preOrderHandler(activeChild)}
                                        className="add_product_to_cart"
                                    >
                                        Уведомить о поступлении
                                    </div>
                                }
                            </div>
                        :
                            <div className={"controll_panel_offers_and_colors"}>
                                <div
                                    onClick={e => Navigate(getOneProductUrl())}
                                    className="add_product_to_cart"
                                >
                                    Перейти к товару
                                </div>
                            </div>
                        }
                </div>
            </div>
        </div>
    );
});


export default OneProductCart;
