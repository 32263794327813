import React, { useContext, useEffect, useState } from 'react';
import './sitemap.css';
// import {Context} from "../../../index";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import ContentBreadcrumbs from '../../components/Breadcrumbs/contentBreadcrumbs/ContentBreadcrumbs';
import { Context } from '../..';
import {getSiteMap} from "../../http/settingsApi";

const Sitemap = () => {
    const [links,setLinks] = useState([]);
    useEffect(() => {
        getSiteMap().then(response => {
            setLinks(response)
        })
        window.scrollTo(0, 0);
    }, [])

    const {settings} = useContext(Context)
    return (
        <div
            style={{marginTop: settings.headerHeight ? settings.headerHeight : 150}}
            className={"page_about"}>
            <div
                className={"breadcrumbs_about"}>
                <ContentBreadcrumbs
                    margTop={settings.headerHeight ? settings.headerHeight : 150}
                    items={[
                        { name: "Главная", link: "/" },
                        { name: "Карта сайта", link: "/sitemap/" }
                    ]}
                />
            </div>
            <Helmet>
                <title>Карта сайта</title>
                <meta name="description" content={'Карта сайта you-wanna.ru'} />
            </Helmet>
            <div className={"about_content_wrapper"}>
                <div className={"sitemap_wrapper"}>
                    {
                        links ?
                            links.map((childItem) => {
                                return(
                                    <div>
                                        <Link to={childItem.link}>{childItem.name}</Link>
                                    </div>
                                )
                            }) : ""
                    }

                </div>

            </div>

        </div>
    );
};

export default Sitemap;
