import React, {useEffect, useState} from 'react';
import {fetchBannedUsers, unbanuser} from "../../../../http/userAPI";
import Pagination from "../../../../components/Paginstion";
import Spiner from "../../spiner/Spiner";
const Limiter = () => {
    const queryParams = new URLSearchParams(window.location.search)
    let page = +queryParams.get('page')
    let limit = 100
    if(!page || page <= 0)
        page = 1

    const [allCount,setAllCount] = useState(0)
    const [usersArray, setUsersArray] = useState(null);
    const [searchQuery,setSearchQuery] = useState();
    const [showPreloader, setShowPreloader] = useState(false);

    useEffect(() => {
        fetchBannedUsers(limit,(page-1)*limit,true, searchQuery).then(response => {
            if (response.error) {
                //Ошибка, пока ничего не делаем
                return false;
            }
            setAllCount(response.count)
            setUsersArray(response.rows);
        })
    }, [page, searchQuery])

    const unban = (id) =>  {
        setShowPreloader(true)
        unbanuser(id).then(() => {
            fetchBannedUsers(limit,(page-1)*limit,true, searchQuery).then(response => {
                setShowPreloader(false)
                if (response.error) {
                    //Ошибка, пока ничего не делаем
                    return false;
                }
                setAllCount(response.count)
                setUsersArray(response.rows);
            })
        })
    }

    return (
        <>
        {
            showPreloader ?
                <Spiner />
                :
        <div className={"users_page_wrapper"}>

            <div className="add_new_user_block">

            </div>
            <div className={"wrapper_all_users"}>
                  <span className={"user_list_page_heading"}>
                         Лист пользователей
                  </span>
                <div className={"controll_and_sort_users_list"}>
                    <div>

                        <input
                            className={"search_user_input"}
                            value={searchQuery ? searchQuery : ""}
                            onChange={ e => setSearchQuery(e.target.value)}
                            placeholder={"Введите номер"} type="text"/>
                    </div>
                </div>
                <div className={"list_all_users"}>
                    {
                        usersArray ?
                            usersArray.map(item =>
                                <div
                                    className={"one_use_line_st limiter"}>
                                    <div className="one_use_container telephone">
                                        Тел: {item.phone}
                                    </div>
                                    <div className={"one_use_container role"}>
                                        <button onClick={e => unban(item.id)} className={"button_view_block"}>Разбанить</button>
                                    </div>
                                </div>)
                            : "Пусто"
                    }
                </div>
                <div className="pagination_adm_wrapper">
                    <Pagination page={page} limit={limit} count={allCount} />
                </div>
            </div>
        </div>
        }
        </>
    );
}

export default Limiter;